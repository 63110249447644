/** @jsx jsx */
import { css, jsx } from '@emotion/core'

// Components
import { Link } from "gatsby";

const Link2 = ({
    children, 
    href,
    id,
    spacing='0px',
    color='#FFFFFF',
    backgroundColor='#745077',
    borderColor='#745077',
    hoverColor='#745077',
    hoverBackgroundColor='#FFFFFF',
    hoverBorderColor,
    align='left',
    fontFamily='Open Sans', 
    fontSize='1.25em',
    linkid, 
    onMouseOver
  }) => {
  
  // Check if the link is an internal link
  const internal = /^\/(?!\/)/.test(href)

  // Internal Link

  if(internal) {
    return(
      <Link
        fade
        id={id}
        name={children}
        data-link-id={linkid}
        onMouseOver={onMouseOver}
        css={css`
          display: inline-block;
          min-width: 150px;
          padding: 10px 0;
          font-weight: 300;
          text-decoration: none;
          transition: 0.2s;
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-smoothing: antialiased;
          
          margin: 0 0 ${spacing} 0;
          border: 2px solid ${borderColor};
          text-align: ${align};
          color: ${color};
          background: ${backgroundColor};
          font-family: ${fontFamily};
          font-size: ${fontSize};
          &:hover {
            color: ${hoverColor};
            background: ${hoverBackgroundColor};
            border: 2px solid ${hoverBorderColor};
          }
        `}
        to={href}
      >
        {children}
      </Link>
    )
  }

  // External Link

  else {
    return(
      <a
        id={id}
        name={children}
        target={'_blank'}
        data-link-id={linkid}
        onMouseOver={onMouseOver}
        css={css`
          display: inline-block;
          min-width: 150px;
          padding: 10px 0;
          font-weight: 300;
          text-decoration: none;
          transition: 0.2s;
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-smoothing: antialiased;
          
          margin: 0 0 ${spacing} 0;
          border: 1px solid ${borderColor};
          text-align: ${align};
          color: ${color};
          background: ${backgroundColor};
          font-family: ${fontFamily};
          font-size: ${fontSize};
          &:hover {
            color: ${hoverColor};
            background: ${hoverBackgroundColor};
            border: 1px solid ${hoverBorderColor};
          }
        `}
        href={href}
      >
        {children}
      </a>
    )
  }
}

export default Link2