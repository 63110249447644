import React from 'react'
/** @jsx jsx*/
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import Header2 from '../atoms/header-2';
import Paragraph1 from '../atoms/paragraph-1';
import Paragraph2 from '../atoms/paragraph-2';
import AmazonLink from '../molecules/amazon-link';

const Container = styled.section`
  width: 100%;
  margin-bottom: 30px;
  padding-bottom: 60px;
  border-bottom: 1px solid #AAAAAA;
`
const ContainerInfo = styled.section`
  width: 100%;
  margin-bottom: 15px;
`
const ContainerMain = styled.section`
  display: flex;
  justify-content: center;
  flex-wrap: no-wrap;
  width: 100%;
  @media (max-width: 700px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`
const ContainerImage = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 450px;
  height: 300px;
  padding: 30px;
  border: 1px solid rgba(247, 76, 76, 0.1);
  @media (max-width: 700px) {
    margin-bottom: 30px;
  }
`
const ContainerControls = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;  
  width: 40%;
  @media(max-width: 700px) {
    width: 100%;
    align-items: center;
  }
`
const ListPlaceholder = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
`
const ItemPlaceholder = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  overflow: hidden;
  border: 2px solid transparent;
  cursor: pointer;
  margin-bottom: 30px;
  &:nth-of-type(n+3) {
    margin-right: 0px;
  }
  > div {
    height: 100%;
    width: 100%;
  }
`

class BookBlock extends React.Component { 
  constructor(props) {
    super(props)

    this.state = {
      imageIndex: 0
    }

    this.changeImage = this.changeImage.bind(this)
  }

  changeImage(target, index) {
    this.setState({imageIndex: index})
  }
  
  render() {
    const { 
      title, 
      subtitle, 
      isbn, 
      category, 
      date, 
      images, 
      placeholderImages,
      amazonLink 
    } = this.props
    return(
      <Container>

        <ContainerInfo>
          <span css={css`display: inline-block; height: 60px; >*{display: inline-block;}>p {margin: 0; line-height: 0;}`}><Header2 color='#8BC4A2' spacing='0'>{title}</Header2> <Paragraph1 color='#4C4C4C'>{subtitle}</Paragraph1></span>
          <br/>
          <Paragraph2 color='#828282'>{isbn} | For {category} | {date}</Paragraph2>
        </ContainerInfo>
        
        <ContainerMain>

          <ContainerImage>
            {images[this.state.imageIndex]}
          </ContainerImage>
          
          <ContainerControls>
            <ListPlaceholder>
              {placeholderImages && 
                placeholderImages.map((image, i) => (
                  <ItemPlaceholder
                    key={i}
                    css={css`
                      border: ${i === this.state.imageIndex ? '2px solid #2F8CF9' : '2px solid transparent;'}
                    `}
                    onClick={() => (this.changeImage(this, i))}>
                    {image}
                  </ItemPlaceholder>
                ))
              }
            </ListPlaceholder>

            {amazonLink && 
              <AmazonLink link={amazonLink}/>
            }
          </ContainerControls>

        </ContainerMain>

      </Container>
    )
  }
}

export default BookBlock