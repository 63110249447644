import React from 'react'
import styled from '@emotion/styled'
import ImgLogoAmazon from '../images/_logo/img-logo-amazon';
import Link2 from '../atoms/link-2';

const Container = styled.section`
  width: 175px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 17px;
  text-align: center;
`

const AmazonLink = ({link}) => (
  <Container>
    <ImgLogoAmazon/>
    <Link2 
      href={link}
      hoverColor={'#8BC4A2'}
      borderColor='#8BC4A2'
      hoverBorderColor={'#8BC4A2'}
      backgroundColor={'#8BC4A2'} 
      align='center' 
      fontSize='1.5em'
    >
      Store
    </Link2>
  </Container>
)

export default AmazonLink