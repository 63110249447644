/** @jsx jsx */
import { css, jsx } from '@emotion/core'

const GridThree = ({ children, change=false }) => (
  <section css={css`
    display: flex;
    justify-content: ${change ? 'space-between' : 'space-evenly'}};
    align-items: flex-end;
    flex-wrap: wrap;
    margin: 60px auto;
    max-width: 1440px;
  `}>
    {children}
  </section>
)

export default GridThree