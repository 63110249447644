/** @jsx jsx*/
import { css, jsx } from '@emotion/core'
import Subtitle1 from '../atoms/subtitle-1';
import Header2 from '../atoms/header-2';
import Link2 from '../atoms/link-2';

const MoreResources = ({link}) => (
  <section css={css`
    margin-left: auto;
    width: 200px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
    text-align: left;
    @media (max-width: 700px) {
      align-items: center;
      margin: 0 auto;
    }
  `}>
    <Header2 color='#4C4C4C' fontSize={'1.25em'} fontFamily={'Open Sans'} spacing={'15px'}>MORE RESOURCES?</Header2>
    <Subtitle1 fontSize={'1em'} color={'#828282'} spacing={'30px'}>Check out our Study Guide!</Subtitle1>
    <Link2 href={link} backgroundColor={'#6396D1'} borderColor='#6396D1' hoverBorderColor='#6396D1' hoverColor='#6396D1' align='center' fontSize='1.5em'>Study Guide</Link2>
  </section>
)

export default MoreResources