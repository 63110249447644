

/** @jsx jsx */
import {jsx, css, Global} from '@emotion/core'
import styled from '@emotion/styled'
import ReadMoreReact from 'read-more-react'

// Components
import Header3 from '../atoms/header-3';

// Styles
const Container = styled.section`
  max-width: 400px;
  margin-bottom: 60px;
`
const ContainerText = styled.section`
  padding: 0 30px;
  margin-bottom: 60px;
`
const ContainerName = styled.section`
  background: #000000;
  padding: 15px 15px;
`

const Review = ({ text, name }) => (
  <Container>
    <Global 
      styles={css`
        .displayed-text {
          color: #828282;
          marigin: 0 0 30px 0;
          font-size: 1.25em;
          line-height: 2;
          font-family: Crimson Text;
          font-weight: '300;
        }
      `}
    />
    <Global 
      styles={css`
        .read-more-button {
          margin-top: 15px;
          color: #000000;
          cursor: pointer;
        }
      `}
    />

    <ContainerText>
      <ReadMoreReact
        text={text} 
        min={200}
        ideal={250}
        max={250}
      />
    </ContainerText>

    <ContainerName>
      <Header3 spacing={0} align='center'>
        {name}
      </Header3>
    </ContainerName>
  </Container>
)

export default Review