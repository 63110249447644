import React from 'react'
/** @jsx jsx*/
import { css, jsx, Global } from '@emotion/core'
import styled from '@emotion/styled'

// Components
import { Spring } from 'react-spring/renderprops'
import Button1 from '../atoms/button-1'
import Paragraph1 from '../atoms/paragraph-1'
import Header2 from '../atoms/header-2';
import Quote from '../atoms/quote';
import Header1 from '../atoms/header-1';


const Container = styled.section`
  margin: 60px 0 120px 0;
  display: flex;
  flex-wrap: wrap;
  button {
    margin-bottom: 15px;
    margin-right: 15px;
  }
  @media(max-width: 720px) {
    justify-content: space-evenly;
  }
`
const ContainerExcerptOverlay = styled.section`
  opacity: 1;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,0.6);
  width: 100%;
  height: 100%;
`
const ContainerExcerpt = styled.section`
  z-index: 1;
  opacity: 1;
  position: fixed;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #FFFFFF;
  padding: 60px;
  max-width: 980px;
  max-height: 80vh;
  @media (max-width: 1024px) {
    height: calc(100vh - 60px);
  }
  @media (max-width: 768px) {
    padding: 60px 30px;
    max-height: initial;
  }
`
const ContainerExcerptText = styled.section`
  margin-bottom: 30px;
  padding-bottom: 30%;
  max-height: 50vh;
  overflow-y: scroll;
  @media (max-width: 1024px) {
    max-height: 80vh;
  }
`

class ExcerptsBlock extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      excerptIndex: 0,
      excerptVisible: false
    }

    this.showExcerpt = this.showExcerpt.bind(this)
    this.closeExcerpt = this.closeExcerpt.bind(this)
  }

  showExcerpt(index) {
    if(typeof window !== 'undefined') {
      document.querySelector('body').style.overflow = 'hidden'
      document.querySelector('#excerpt-overlay-text').scrollTo(0, 0)
    }

    this.setState({
      excerptIndex: index,
      excerptVisible: true,
    })
  }

  closeExcerpt(e) {
    if(typeof window !== 'undefined') {
      document.querySelector('body').style.overflow = 'visible'
    }

    if(e.target.id === 'excerpt-overlay' || e.target.tagName === 'BUTTON') {
      this.setState({
        excerptVisible: false,
      })
    }
  }

  render() {
    const { 
      excerpts,
    } = this.props
    
    return (
      <Container>

        {excerpts &&
          excerpts.map((excerpt, i) => (
            <Button1 key={i} onClick={() => (this.showExcerpt(i))}>Excerpt {i+1}</Button1>
          ))
        }

        <Spring
          from={{
            opacity: 0,
          }}
          to={{
            opacity: this.state.excerptVisible ? '1' : '0',
            pointerEvents: this.state.excerptVisible ? 'initial' : 'none',
            transform: this.state.excerptVisible ? 'translateY(0)' : 'translateY(60px)'
          }}
        >
          {props => (
            <ContainerExcerptOverlay 
              style={{
                opacity: `${props.opacity}`,
                pointerEvents: `${props.pointerEvents}`
              }} 
              id='excerpt-overlay' 
              onClick={this.closeExcerpt}
            >
              {this.state.excerptVisible && <Global styles={css` body { overflow:hidden; }`}/>}
              <ContainerExcerpt 
                style={{
                  transform: `${props.transform}`
                }}>
                <ContainerExcerptText id='excerpt-overlay-text'>
                  <Header1 align='center'>
                    {excerpts[this.state.excerptIndex].chapter}
                  </Header1>
                  <div css={css`text-align: center`}>
                    <Header2 align='center' spacing={'0'}>
                      {excerpts[this.state.excerptIndex].title}
                    </Header2>
                  </div>
                  <Quote align='center' fontSize='2.5em'>{excerpts[this.state.excerptIndex].scripture}</Quote>
                  {excerpts[this.state.excerptIndex].text && 
                    excerpts[this.state.excerptIndex].text.map((text, i) => (
                      <Paragraph1 key={i}>{text}</Paragraph1>
                    ))
                  }
                </ContainerExcerptText>
                <Button1 onClick={this.closeExcerpt}>Close</Button1>
              </ContainerExcerpt>

            </ContainerExcerptOverlay>
          )}
        </Spring>
      </Container>
    )
  }
}

export default ExcerptsBlock